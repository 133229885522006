import axios from 'axios';
import { ACCOUNT } from './types';

export const getAccount = data => async dispatch => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/account/`, data);
  dispatch({ type: ACCOUNT, payload: res.data });
  return res.data;
}

export const payForReservation = data => async () => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/account/reservation/_pay/`, data);
  return res.data;
}

export const setTransferForReservation = data => async () => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/account/reservation/_transfer/`, data);
  return res.data;
}

export const searchUnits = data => async () => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/units/_search/`, data);
  return res.data;
}

export const searchPrices = data => async () => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/prices/_search/`, data);
  return res.data;
}

export const setReservationItem = data => async () => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/reservation/item/_update/`, data);
  return res.data;
}

export const removeReservationItem = data => async () => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/reservation/item/_remove/`, data);
  return res.data;
}
