export const getLocationData = (t, window) => {
  const locationData = {};
  try {
    const location = t.props.location || window.location;
    if (location) {
      const fields = ['href', 'protocol', 'host', 'hostname', 'port', 'pathname', 'search', 'hash'];
      fields.forEach(field => {
        if (location[field]) {
          locationData[field] = location[field];
        }
      });
      locationData.hash = location.hash && location.hash.indexOf('?') > -1 ? location.hash.substring(0, location.hash.indexOf('?')) : location.hash;
      let search = location.hash && location.hash.indexOf('?') > -1 ? location.hash.substring(location.hash.indexOf('?')) : location.search;
      locationData.search = search;
      while (search.startsWith('?')) {
        search = search.substring(1);
      }
      search = search.split('&');
      const params = {};
      search.forEach(param => {
        if (param && param.indexOf('=') > -1) {
          const key = param.substring(0, param.indexOf('='));
          const value = decodeURIComponent(param.substring(param.indexOf('=') + 1, param.length));
          params[key] = value;
        }
      });
      locationData.params = params;
    }
  } catch (err) {}
  return locationData;
};

export const capitalize = s => {
  if (typeof s !== 'string')
    return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getPathHeaderMeta = path => {
  path = path ? path.replace('/th', '').replace('/en', '') : path;
  path = !path ? '/' : path;
  let title = process.env.REACT_APP_DEFAULT_TITLE;
  let description = process.env.REACT_APP_DEFAULT_DESCRIPTION;
  let url = process.env.REACT_APP_WEBSITE_URL;
  switch (path) {
    case '/timeout':
      title = `${process.env.REACT_APP_APP_NAME} - Timeout`;
      url = `${url}${path}`;
      break;
    default:
      break;
  }
  return path ?
    { browser_title: `${path === '/' ? process.env.REACT_APP_DEFAULT_BROWSER_TITLE : path === '/timeout' ? 'Timeout' : path === '/404' ? 'Page Not Found' : path === '/503' ? 'Under Maintenance' : capitalize(path.slice(1))} | ${process.env.REACT_APP_APP_NAME}`, title, description, url }
    :
    null;
};
