import React from 'react';

const Index = React.lazy(() => import('./pages/Home/index'));
const PageTimeout = React.lazy(() => import('./pages/Pages/Special/PageTimeout'));
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));
const PageMaintenance = React.lazy(() => import('./pages/Pages/Special/PageMaintenance'));

const routes = [
  { path: '/timeout', component: PageTimeout, isWithoutLayout: true, exact: true },
  { path: '/th/timeout', component: PageTimeout, isWithoutLayout: true, exact: true },
  { path: '/en/timeout', component: PageTimeout, isWithoutLayout: true, exact: true },
  { path: '/404', component: PageError, isWithoutLayout: true, exact: true },
  { path: '/503', component: PageMaintenance, isWithoutLayout: true, exact: true },
  { path: '/:id', component: Index },
  { path: '/th/:id', component: Index },
  { path: '/en/:id', component: Index },
  { component: PageError, isWithoutLayout: true, exact: false },
];
export default routes;
