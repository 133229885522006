import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Layout from './components/Layout/';
import routes from './routes';
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/default.css';
import './assets/css/app.css';
import 'antd/dist/antd.css';

const withLayout = WrappedComponent => {
  return class extends React.Component {  
    render() {
      return (
        <Layout>
          <WrappedComponent />
        </Layout>
      );
    }
  };
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>  
        <Router>
          <React.Suspense fallback={this.Loader()}>
            <Switch>
              {routes.map((route, idx) =>
                route.isWithoutLayout ?
                  <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
                  :
                  <Route path={route.path} exact={route.exact} component={withLayout(route.component)} key={idx} />
              )}
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
