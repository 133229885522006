import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ScrollUpButton from 'react-scroll-up-button';
import FeatherIcon from 'feather-icons-react';
import { getLocationData } from '../../utils';
import * as actions from '../../actions';

const Topbar = React.lazy(() => import('./Topbar'));
const Footer = React.lazy(() => import('./Footer'));

const CustomDot = () => {
  return (
    <i><FeatherIcon icon="arrow-up" className="icons" /></i>
  );
};

class Layout extends React.Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const locationData = getLocationData(this, window);
    let lang = locationData.pathname.startsWith('/th') ? 'th' : locationData.pathname.startsWith('/en') ? 'en' : '';
    if (!lang) {
      const browserLocale = require('browser-locale')();
      lang = browserLocale && (browserLocale.toLowerCase() === 'th-th' || browserLocale.toLowerCase() === 'th') ? 'th' : 'en';
    }
    const { children, account } = this.props;
    let title = process.env.REACT_APP_DEFAULT_TITLE;
    let description = process.env.REACT_APP_DEFAULT_DESCRIPTION;
    let image = null;
    let url = `${process.env.REACT_APP_HOST_URL}`;
    if (account) {
      const accommodation_images = account.images_ss ? account.images_ss : [];
      const myDynamicManifest = {
        short_name: account.accommodation_name_s,
        name: account.accommodation_name_s,
        icons: [
          {
            src: account.logo_s ? account.logo_s : accommodation_images.length > 0 ? accommodation_images[0] : account.image_s ? `${process.env.REACT_APP_HOST_URL}/image/${account.id}` : `${process.env.REACT_APP_HOST_URL}/android-icon-36x36.png`,
            sizes: '36x36',
            type: 'image/png',
            density: '0.75'
          },
          {
            src: account.logo_s ? account.logo_s : accommodation_images.length > 0 ? accommodation_images[0] : account.image_s ? `${process.env.REACT_APP_HOST_URL}/image/${account.id}` : `${process.env.REACT_APP_HOST_URL}/android-icon-48x48.png`,
            sizes: '48x48',
            type: 'image/png',
            density: '1.0'
          },
          {
            src: account.logo_s ? account.logo_s : accommodation_images.length > 0 ? accommodation_images[0] : account.image_s ? `${process.env.REACT_APP_HOST_URL}/image/${account.id}` : `${process.env.REACT_APP_HOST_URL}/android-icon-72x72.png`,
            sizes: '72x72',
            type: 'image/png',
            density: '1.5'
          },
          {
            src: account.logo_s ? account.logo_s : accommodation_images.length > 0 ? accommodation_images[0] : account.image_s ? `${process.env.REACT_APP_HOST_URL}/image/${account.id}` : `${process.env.REACT_APP_HOST_URL}/android-icon-96x96.png`,
            sizes: '96x96',
            type: 'image/png',
            density: '2.0'
          },
          {
            src: account.logo_s ? account.logo_s : accommodation_images.length > 0 ? accommodation_images[0] : account.image_s ? `${process.env.REACT_APP_HOST_URL}/image/${account.id}` : `${process.env.REACT_APP_HOST_URL}/android-icon-144x144.png`,
            sizes: '144x144',
            type: 'image/png',
            density: '3.0'
          },
          {
            src: account.logo_s ? account.logo_s : accommodation_images.length > 0 ? accommodation_images[0] : account.image_s ? `${process.env.REACT_APP_HOST_URL}/image/${account.id}` : `${process.env.REACT_APP_HOST_URL}/android-icon-192x192.png`,
            sizes: '192x192',
            type: 'image/png',
            density: '4.0'
          },
        ],
        start_url: `/${account.id}`,
        display: 'standalone',
        theme_color: '#2f55d4',
        background_color: '#ffffff'
      };
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#my-manifest').setAttribute('href', manifestURL);
      title = lang === 'th' && account.accommodation_name_th_s ? account.accommodation_name_th_s : lang === 'en' && account.accommodation_name_en_s ? account.accommodation_name_en_s : account.accommodation_name_s;
      description = lang === 'th' && account.description_th_s ? account.description_th_s : lang === 'en' && account.description_en_s ? account.description_en_s : account.description_th_s ? account.description_th_s : account.description_en_s ? account.description_en_s : `${account.accommodation_name_s ? account.accommodation_name_s : ''}${account.accommodation_type_s ? `${account.accommodation_name_s ? ' ' : ''}(${account.accommodation_type_s})` : ''} ${process.env.REACT_APP_DEFAULT_DESCRIPTION}`;
      image = account.logo_s ? account.logo_s : accommodation_images.length > 0 ? accommodation_images[0] : account.image_s ? `${process.env.REACT_APP_HOST_URL}/image/${account.id}` : `${process.env.REACT_APP_AWS_S3_URL}/${process.env.REACT_APP_AWS_S3_BUCKET}/resource/logo_rectangle.png`;
      url = `${process.env.REACT_APP_HOST_URL}/${account.id}`;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="og:site_name" property="og:site_name" content={title} />
          <meta name="og:title" property="og:title" content={title} />
          <meta name="og:description" property="og:description" content={description} />
          <meta name="og:image" property="og:image" content={image} />
          <meta name="og:url" property="og:url" content={url} />
          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="thumbnailUrl" content={image} />
          <meta itemprop="image" content={image} />
          <meta itemprop="url" content={url} />
          <meta itemprop="headline" content={title} />
          <meta itemprop="publisher" content={title} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:url" content={url} />
          <link rel="image_src" href={image} />
          <link rel="canonical" href={url} />
        </Helmet>
        <React.Suspense fallback={this.Loader()}>
          <Topbar lang={lang} />
          {children}
          <Footer lang={lang} />
          <ScrollUpButton ContainerClassName="classForContainer" style={{ height: 36, width: 36, marginBottom: '3.75rem', marginRight: '-.5rem' }} TransitionClassName="classForTransition">
            <CustomDot />
          </ScrollUpButton>
          {children && account && (
            <MessengerCustomerChat
              pageId={account.facebook_page_id_s ? account.facebook_page_id_s : process.env.REACT_APP_FACEBOOK_PAGE_ID}
              appId={account.facebook_page_id_s ? undefined : process.env.REACT_APP_FACEBOOK_APP_ID}
              loggedInGreeting={account.facebook_greeting_s ? account.facebook_greeting_s : account.facebook_page_id_s ? undefined : process.env.REACT_APP_FACEBOOK_GREETING}
              loggedOutGreeting={account.facebook_greeting_s ? account.facebook_greeting_s : account.facebook_page_id_s ? undefined : process.env.REACT_APP_FACEBOOK_GREETING}
              themeColor={account.facebook_theme_color_s ? account.facebook_theme_color_s : undefined}
            />
          )}
        </React.Suspense>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ account }) => { return { account }; }
export default connect(mapStateToProps, actions)(withRouter(Layout));
